<template>
  <div id="user-profile">
    <b-navbar
      class="header-navbar navbar-shadow align-items-center navbar-brand-center navbar-fixed"
    >
      <profile-header />
    </b-navbar>
    <section id="profile-info">
      <b-row>
        <b-col lg="3" cols="12"> </b-col>

        <!-- post -->
        <b-col lg="6" cols="12">
          <pembayaran :data="data" />
        </b-col>
        <!-- post -->
      </b-row>
    </section>
    <!--/ profile info  -->
  </div>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";

import ProfileHeader from "./components/Navbar.vue";
import Pembayaran from "./utama/Pembayaran.vue";
/* eslint-disable global-require */
export default {
  components: {
    BRow,
    BCol,
    Pembayaran,
    ProfileHeader,
  },
  data() {
    return {
      loading: false,
      data: {},
    };
  },
  methods: {
    getData() {
      this.loading = true;
      let id = this.$route.params.id;

      this.$store
        .dispatch("paketTryout/detail", { id })
        .then((res) => {
          this.loading = false;
          this.data = res.data;
        })
        .catch((err) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error `,
              icon: "XIcon",
              variant: "danger",
              text: err.response.data.message,
            },
          });
        });
    },
  },
  created() {
    if (!this.user) {
      this.$router.push({ name: "auth-login" });
    }
    this.getData();
  },
};
/* eslint-disable global-require */
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-profile.scss";
</style>
